import React from "react";
import ReactFullpage, { Item } from "@fullpage/react-fullpage";
import Home from "../Home/home";
import Hometwo from "../Hometwo/hometwo";
import "./index.scss";
import { list } from "postcss";

let page = 0;
const Menulist = () => (
  <ReactFullpage
    //fullpage options
    licenseKey={"YOUR_KEY_HERE"}
    scrollingSpeed={300} /* Options here */
    scrollOverflow={false}
    scrollBarx={true}
    
    // fitToSection = {true}
    onLeave={(_origin, destination, _direction) => {
      page = destination.index;
    }}
    render={() => {
      return (

        <>
        <div className="top"><div className='mark'></div>
      <div className='nav'>下载APP&nbsp; &nbsp; &nbsp; &nbsp;
        <a className='aim' href="#hometwo">关于盒子</a>
      </div></div>
        <ReactFullpage.Wrapper>
          <div
            className="section"
            style={
              page == 0
                ? { transition: "all 1.2s", opacity: "1", transform: "translateY(0px)" }
                : { transition: "all 1.2s", opacity: "0", transform: "translateY(0px)" }
            }
          >
            <Home />
          </div>
          <div
            className="section"
            style={
              page == 1
                ? { transition: "all 1.2s", opacity: "1", transform: "translateY(0px)" }
                : { transition: "all 1.2s", opacity: "0", transform: "translateY(0px)" }
            }
          >
            <Hometwo/>
          </div>
        </ReactFullpage.Wrapper>
        </>
      );
    }}
  />
);
export default Menulist;