import 'antd/dist/antd.css';
import React, { useState } from 'react'
import "./home.scss"
import { Button, Modal } from 'antd';




export default function Home() {
  const info = () => {
    Modal.info({
      title: '扫码下载APP',
      content: (
        <div>
          <p>码</p>
          
        </div>
      ),
      onOk() {},
      centered() {},
      
    });
  };
  return (
    <div className='homeone' id='homeone'>
      
      <div className='lib'></div>
      <div className='content'>
        <div className='content-left'>
          <h2>探索新世界</h2>
          <p>我们帮助创作者、艺术家、品牌和企业通过NFTs解锁新的经济机会</p>
          <div className='download'>
            <Button type="dashed" shape="round" >
              安卓下载
            </Button>
            <Button type="dashed" shape="round" >
              苹果下载
            </Button>
          </div>
          <div className='bottom'>
            <div onClick={info} >
              <img src={require("./mark1.png")} alt="" />
            </div>
            <div>
              <img src={require("./mark2.png")} alt="" />
            </div>
          </div>
        </div>
        <div className='content-right'></div>
      </div>


    </div>
  )

}

