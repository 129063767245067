import React from 'react'

import App from './App'
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";


const root = createRoot(document.getElementById("root"));
// 根可用于将 React 元素渲染到 DOM 中
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

