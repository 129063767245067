
import React from "react";

import "./index.scss";
import Menulist from "./pages/list/menulist";

function App() {

  return (

    <>
      <div className="top"><div className='mark'></div>
        <div className='nav'>下载APP&nbsp; &nbsp; &nbsp; &nbsp;
          <a className='aim' href="#hometwo">关于盒子</a>
        </div></div>
      <Menulist />
    </>
  );

};
export default App;