import React, { Component } from 'react';
import "./index.scss"
import { Button } from 'antd';

function Hometwo() {

    return (
        <div className='hometwo'  id='hometwo'>
            <div className='lib'></div>
            <div className='content'>
                <div className='content-left'>
                    <h2>创作者计划</h2>
                    <p>志气平台致力于让所有热爱数字艺术品的艺术家，有一个展示自己作品的平台，同时区块链技术可以很好地解决传统互联网时代“复制黏贴”无法有效保护知识产权的顽疾。
                        <br /><br />
                       它可以帮助明确权利所有者与使用者之间的关系，这对于尊重数字内容创新和保护知识产权都有非常重要的积极意义。</p>
                    <div className='download'>
                        <Button type="dashed" shape="round" >
                            联系我们
                        </Button>
                    </div>
                </div>
                <div className='content-right'></div>
            </div>
        </div>
    )
}

export default Hometwo